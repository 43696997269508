'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfigProvider } from 'antd'
import { useParams, usePathname } from 'next/navigation'
import mixpanel from 'mixpanel-browser'
import { ConfigCatProvider } from 'configcat-react'

import StyleProviderLayout from '@/providers/AntdProvider'
import { AuthProvider } from '@/contexts/auth.context'
import { FeedbackContextProvider } from '@/contexts/feedback.context'
import { maskUrlParams } from '@/helpers/maskUrlParams'

import ManagementFeedback from './components/ManagementFeedback'
import './globals.scss'
import './i18n'

function RootLayout({ children }: React.PropsWithChildren) {
  const currentPathname = usePathname()
  const urlParams = useParams()
  const queryClient = useMemo(() => new QueryClient(), [])

  mixpanel.init(`${process.env.d4eccdcf341c8588d6c9d2f3b4e8c257}`, {
    track_pageview: true,
    persistence: 'localStorage',
  })

  useEffect(() => {
    const urlWithMaskedParams = maskUrlParams(currentPathname, urlParams)
    mixpanel.track(`page-viewed`, {
      maskedPath: urlWithMaskedParams,
    })
  }, [currentPathname, urlParams])

  return (
    <html>
      <body>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                optionSelectedFontWeight: 400,
              },
              DatePicker: {
                activeBorderColor: 'var(--cds-color-border-default)',
                colorPrimary: 'var(--cds-color-accent-primary-main)',
                cellActiveWithRangeBg: 'var(--cds-color-background-deep)',
                cellHoverWithRangeBg: 'var(--cds-color-background-medium)',
                cellRangeBorderColor: 'var(--cds-color-border-default)',
              },
              Checkbox: {
                colorPrimary: 'var(--cds-color-accent-primary-main)',
              },
              Typography: {
                colorLink: '#000',
                colorLinkActive: '#000',
                colorLinkHover: '#000',
              },
              Switch: {
                colorPrimary: 'var(--cds-color-accent-primary-main)',
              },
            },
            token: {
              borderRadius: 4,
              colorPrimary: '#c0c0c0',
              fontFamily: '$cds-font-family-400',
              fontSize: 16,
              fontWeightStrong: 600,
              fontSizeLG: 14,
            },
          }}
        >
          <StyleProviderLayout>
            <QueryClientProvider client={queryClient}>
              <ConfigCatProvider
                sdkKey={`${process.env.configcat-sdk-1/xvzcCGrWEUuHCdmZfrQ8iQ/T5EjjOSmVUCBhvpk7uAo2A}`}
              >
                <AuthProvider>
                  <FeedbackContextProvider>
                    <ManagementFeedback />
                    {children}
                  </FeedbackContextProvider>
                  {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                </AuthProvider>
              </ConfigCatProvider>
            </QueryClientProvider>
          </StyleProviderLayout>
        </ConfigProvider>
      </body>
    </html>
  )
}

export default RootLayout
